import React, { useCallback } from "react";
import { Layout } from "src/layouts";
import { navigate } from "gatsby";
import styled from "styled-components";
import { ProjectProps } from "src/utilities/interfaces/interfaces";
import { allProjectData } from "src/utilities/projectData";

const ProjectsSection = styled.section`
  margin-top: 16px;
`;

const ProjectsWrapper = styled.article`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
`;

const ProjectTileContainer = styled.section`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: start;
  gap: 20px;

  .anchorContainer {
    cursor: pointer;
    position: relative;
    text-wrap: wrap;
    width: 256px;
    height: 256px;
    svg,
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 8px;
    }

    .overlay {
      width: 100%;
      height: 100%;
      padding: 8px;
      position: absolute;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 10px;

      .description {
        background: #000b;
        margin: -8px;
        padding: 8px;
        border-radius: 0 0 8px 8px;
      }
    }
  }
`;

const Projects = () => {
  const handleProjectClicked = useCallback((props: ProjectProps) => {
    navigate(props.detailsUrl);
  }, []);

  const createProjects = (): React.ReactElement[] => {
    let elements: React.ReactElement[] = [];

    const buildProject = (projectData: ProjectProps): React.ReactElement => {
      return (
        <div
          className="anchorContainer"
          key={projectData.title}
          onClick={() => handleProjectClicked(projectData)}
        >
          {projectData.image && (
            <projectData.image alt={`Image for ${projectData.title}`} />
          )}
          {projectData.imageUrl && (
            <img
              src={projectData.imageUrl}
              alt={`Image for ${projectData.title}.`}
            />
          )}
          <div className="overlay">
            <h3>{projectData.title}</h3>
            <div className="description">
              <p>{projectData.description}</p>
            </div>
          </div>
        </div>
      );
    };

    allProjectData.forEach((data) => {
      elements.push(buildProject(data));
    });

    return elements;
  };

  return (
    <Layout>
      <h1>My projects</h1>

      <p>
        Here's a short list of some of the projects that I've worked on over the
        years. There are a couple that are still active, and a few that are
        retired, and a bunch that I've done as part of a team at work. Except
        for the projects that I've done at work, I did all the coding and design
        (and poor naming) myself.
      </p>

      <ProjectsSection>
        <h2 className="sectionHeader">Current side projects</h2>
        <ProjectsWrapper>
          <ProjectTileContainer>{createProjects()}</ProjectTileContainer>
        </ProjectsWrapper>
      </ProjectsSection>
    </Layout>
  );
};

export default Projects;
